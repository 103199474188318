// import logo from './logo.svg';
import React, { useState } from "react";
import './App.css';

const animals = [
  { name: "Lion", imgSrc: "/images/lion.png", funFact: "The Masai Mara in Kenya boasts a dense concentration of big cats! Join me in Kenya this September or December." },
  { name: "Wildebeest", imgSrc: "/images/wildebeest.png", funFact: "You can see the wildebeest during the Great Migration in the Masai Mara National Reserve! Join me in Kenya this September or December." },
  { name: "Rhino", imgSrc: "/images/rhino.png", funFact: "According to the Mara Conservancy, \"The Maasai Mara National Reserve is the only protected area in Kenya with an indigenous black rhino population, unaffected by translocations.\" Join me in Kenya this September or December." },
  { name: "Hippo", imgSrc: "/images/hippo.png", funFact: "Zambia is home to the largest hippo population in the world!" },
];

function App() {
  const [selected, setSelected] = useState(null);

  const handleClick = (animal) => {
    setSelected(animal);
  };

  const selectedAnimal = animals.find((animal) => animal.name === selected);
  // Use the find method to locate the animal object based on the selected name.

  return (
    <div className="App">
      <h1>Which animal would you want to see most on a safari?</h1>
      {selected ? (
        <div>
          <h2>You selected: {selected}</h2>
          <p>{selectedAnimal.funFact}</p>
        </div>
      ) : (
        <div className="quiz-grid">
          {animals.map((animal, index) => (
            <div
              key={index}
              className="quiz-tile"
              onClick={() => handleClick(animal.name)}
            >
              <img src={animal.imgSrc} alt={animal.name} />
              <h3>{animal.name}</h3>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
}

export default App;
